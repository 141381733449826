<template>
    <v-container fluid>
        <v-alert
        dense
        outlined
        type="error"
        v-if="Object.keys(validation_errors).length > 0"
        >
        <ul>
            <li v-for="(errors, field) in validation_errors" :key="field">
                {{ field }}
                <ul>
                    <li v-for="(error, sl) in errors" :key="sl">
                        {{ error }}
                    </li>
                </ul>
            </li>
        </ul>
        </v-alert>

        <v-row>
            <v-col cols="12" class="py-1">
                <v-card :elevation="1">
                    <v-toolbar color="white" :elevation="1" height="30px">
                        <v-toolbar-title class="subtitle-2"
                            >Leave Type</v-toolbar-title
                        >
                    </v-toolbar>

                    <v-card-text class="py-0">
                        <v-form
                            @submit.prevent="saveDepartment"
                            ref="departmentForm"
                            class="custom-form"
                        >
                            <v-row>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col
                                            cols="1"
                                            class="py-1 caption text-center"
                                            >Name</v-col
                                        >
                                        <v-col cols="2" class="py-0">
                                            <v-text-field
                                                v-if="!editId || editId != 1"
                                                dense
                                                outlined
                                                hide-details
                                                :rules="[(v) => !!v]"
                                                v-model.trim="department.name"
                                                :error-messages="validation_errors.name"
                                            ></v-text-field>
                                            <span v-else>{{ department.name }}</span>
                                        </v-col>

                                        <v-col
                                            cols="1"
                                            class="py-1 caption text-center"
                                            >Description</v-col
                                        >
                                        <v-col cols="3" class="py-0">
                                            <v-text-field
                                                dense
                                                outlined
                                                hide-details
                                                v-model.trim="department.description"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col
                                            cols="1"
                                            class="py-1 caption text-center"
                                            >Leave</v-col
                                        >
                                        <v-col cols="1" class="py-0">
                                            <v-text-field
                                                dense
                                                outlined
                                                hide-details
                                                type="number"
                                                min="0"
                                                :rules="[(v) => !!v]"
                                                v-model.number="department.total_leave"
                                                :error-messages="validation_errors.total_leave"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="3" class="py-0">
                                            <v-btn
                                                type="submit"
                                                height="26px"
                                                dark
                                                color="light-blue darken-2"
                                                :elevation="1"
                                                class="mr-1"
                                                :loading="loading"
                                                >Save</v-btn
                                            >
                                            <v-btn
                                                height="26px"
                                                dark
                                                color="deep-orange"
                                                :elevation="1"
                                                @click="resetForm"
                                                >Cancel</v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="departmentHeaders"
                    :items="$store.getters['leave/leaveTypes']"
                    :search="searchDepartment"
                >
                    <template v-slot:top>
                        <v-toolbar
                            dense
                            color="white"
                            :elevation="1"
                            height="30px"
                        >
                            <v-toolbar-title class="subtitle-2"
                                >Leave Type List</v-toolbar-title
                            >
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Department"
                                    append-icon="mdi-magnify"
                                    style="width: 300px"
                                    v-model="searchDepartment"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom v-if="$store.state['user'].role != 'User'">
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="editDepartment(item)"
                                    color="primary"
                                    v-on="on"
                                    >mdi-circle-edit-outline</v-icon
                                >
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="$store.state['user'].role == 'Admin' && item.id != 1">
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="deleteDepartment(item)"
                                    color="error"
                                    v-on="on"
                                    >mdi-delete-circle-outline</v-icon
                                >
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Departments",

    data() {
        return {
            departmentHeaders: [
                { text: "Name", value: "name" },
                { text: "Description", value: "description" },
                { text: "Leave", value: "total_leave" },
                { text: "Action", value: "action" },
            ],
            searchDepartment: "",

            department: {
                name: "",
                description: "",
                total_leave: "",
            },
            editId: null,
            validation_errors: {},
            loading: false,
        };
    },

    created() {
        this.$store.dispatch("leave/getLeaveTypes");
    },

    methods: {
        async saveDepartment() {
            if (!this.$refs.departmentForm.validate()) return;

            this.loading = true;

            let data = {
                url: "/add_leave_type",
                leave_type: this.department,
            };

            if (this.editId) {
                data.url = "/update_leave_type";
                data.leave_type.id = this.editId;
            }

            let resObj = await this.$store.dispatch(
                "leave/saveLeaveType",
                data
            );

            if(resObj.hasOwnProperty("validation_errors")){
                this.validation_errors = resObj.validation_errors;
            }
            this.loading = false;

            if(resObj.isSuccess) {
                this.resetForm();
            }

        },
        editDepartment(department) {
            Object.keys(this.department).forEach(
                (k) => (this.department[k] = department[k])
            );

            this.editId = department.id;
        },
        deleteDepartment(department) {
            if (!confirm("Are you sure?")) return;

            this.$store.dispatch("leave/deleteLeaveType", department.id);
        },
        resetForm() {
            Object.keys(this.department).forEach(
                (k) => (this.department[k] = "")
            );

            this.editId = null;
            this.$refs.departmentForm.resetValidation();
            this.validation_errors = {};
        },
    },
};
</script>

<style>
</style>
